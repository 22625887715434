import React from 'react'
import styled from 'styled-components'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import {
  Control,
  Controller,
  DeepRequired,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form'

import { theme } from '@/styles/theme'
import { ContactCancel } from '@/store/contactCancel/types'

import { Spacer } from '@/components/elements/Spacer'

import 'react-datepicker/dist/react-datepicker.css'

type FormDatePickerProps = {
  id: string
  control: Control<ContactCancel, any>
  defaultValue: Date
  subText?: string
  errorMessage?:
    | Merge<FieldError, FieldErrorsImpl<DeepRequired<Date>>>
    | undefined
}

export const FormDatePicker = ({
  id,
  subText,
  control,
  defaultValue,
  errorMessage,
}: FormDatePickerProps) => {
  const today = new Date()
  registerLocale('ja', ja)

  return (
    <Wrapper>
      {subText && <SubText htmlFor={id}>{subText}</SubText>}
      <DatePickerStyle>
        <Controller
          control={control}
          name="birthday"
          rules={{
            required: {
              value: true,
              message: '入力してください。',
            },
          }}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => (
            <StyledReactDatePicker
              id={id}
              onChange={onChange}
              selected={value}
              locale="ja"
              dateFormat="yyyy/MM/dd"
              maxDate={today}
              className="date-picker"
              dateFormatCalendar={'yyyy年 MM月'}
              calendarStartDay={1}
              autoComplete="off"
            />
          )}
        />
      </DatePickerStyle>
      {errorMessage && (
        <>
          <Spacer h={{ sp: 6, pc: 6 }} />
          <ErrorMessage>エラー：{errorMessage.message}</ErrorMessage>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1047px) {
    span {
      font-size: 12px;
    }
    width: 100%;
  }
`

const SubText = styled.label`
  font-size: 18px;
  font-weight: bold;
`

const DatePickerStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.backgroundGrey};

  width: 640px;
  height: 62px;
  border: 1px solid ${theme.colors.borderGrey};
  border-radius: 8px;
  padding: 22px;
  font-size: 18px;

  > div {
    width: 100%;
  }

  .date-picker {
    cursor: pointer;
  }

  @media screen and (max-width: 1047px) {
    display: none;
  }
`

const StyledReactDatePicker = styled(ReactDatePicker)`
  width: 100%;
`

const ErrorMessage = styled.span`
  color: ${theme.colors.accent};

  font-size: 16px;
  font-weight: bold;
`
