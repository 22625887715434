import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

import useFormInput from '@/hooks/useFormInput'
import { setContactCancel } from '@/store/contactCancel/action'
import { ContactCancel } from '@/store/contactCancel/types'
import { useFileValidation } from '@/hooks/useFileValidation'
import { RootState } from '@/store'

import { Layout } from '@/components/layouts/Layout'
import { Seo } from '@/components/Seo'
import { Title } from '@/components/elements/Title'
import { BreadCrumbs } from '@/components/layouts/Breadcrumbs'
import { FormInput } from '@/components/layouts/Form/FormInput'
import { FormDatePicker } from '@/components/layouts/Form/FormDatePicker'
import { Button } from '@/components/elements/Button'

import {
  Caution,
  FormAction,
  FormContent,
  FormHeader,
  FormMain,
} from '@/styles/form'
import { theme } from '@/styles/theme'
import { FormFooter } from '@/components/layouts/Form/FormFooter'
import { MainWrapper, TitleWrapper } from '@/styles/common'
import { FormLabel } from '@/components/layouts/Form/FormLabel'
import { FormFileUpload } from '@/components/layouts/Form/FormFileUpload'
import { FormDateSelect } from '@/components/layouts/Form/FormDateSelect'
import { Link } from '@/components/elements/Link'

export default function cancel() {
  const form = useSelector((state: RootState) => state.contactCancel)
  const dispatch = useDispatch()
  const { sliceMaxLength } = useFormInput()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<ContactCancel>()

  const fileValidation = useFileValidation<ContactCancel>(
    form,
    setValue,
    errors,
    clearErrors
  )
  const { files, otherFiles, fileErrors, setFileErrors } = fileValidation

  const onSubmit = (data: ContactCancel) => {
    data.require_files = [...files].filter((file) => file)
    data.other_files = [...otherFiles].filter((file) => file)
    dispatch(setContactCancel(data))
    navigate('/contact/cancel/confirm/')
  }

  const breadCrumbs = [
    { name: 'トップ', path: '/' },
    { name: 'お問い合わせ', path: '/contact/' },
    { name: '解約申請フォーム' },
  ]

  return (
    <Layout isForm>
      <Seo
        title="解約申請 - お問い合わせ"
        canonical="https://su-pay.jp/contact/cancel/"
        description="webからSU-PAYを解約される場合は、こちらから申請をお願いします。"
      />
      <Title as="h1" bg={true}>
        お問い合わせ
      </Title>
      <MainWrapper>
        <BreadCrumbs breadCrumbs={breadCrumbs} />
        <TitleWrapper>
          <Title as="h2" color={theme.colors.supayBlue}>
            解約申請フォーム
          </Title>
        </TitleWrapper>
        <FormHeader color="blue">
          <Caution>
            <div>
              <p>SU-PAYアプリの解約のお手続きを行います。</p>
              <p>
                手元にスマートフォンがある場合は
                <Link link="/faq/suspension/n8za0x0z8/" text="こちら" />
                から手続きをしてください。
              </p>
              <p>
                手元にスマートフォンがない場合は以下の解約申請フォームから手続きをしてください。
              </p>
              <p>以下をご確認のうえ、必要情報を入力してください。</p>
            </div>
            <p>【注意事項】</p>
            <ul>
              <li>
                解約すると、登録していた携帯電話番号ではログインできません。
              </li>
              <li>
                以下の情報が削除されます。
                <ol>
                  <li>SU-PAY残高・ポイント</li>
                  <li>
                    ユーザー情報（携帯電話番号、名前、住所、生年月日、性別、パスコード、銀行口座情報）
                  </li>
                </ol>
              </li>
              <li>180日間、同一の携帯電話番号で新規登録はできません。</li>
            </ul>
            <div>
              <p>※</p>
              <p>
                迷惑メール防止のためにドメイン指定受信をしている方は、あらかじめ設定を解除するか「@su-pay.jp」と「@trial-net.co.jp」の受信設定をお願いします。
              </p>
            </div>
          </Caution>
        </FormHeader>
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormMain>
            <FormLabel isRequired>お名前</FormLabel>
            <FormContent>
              <FormInput
                id="name"
                type="text"
                placeholder="山田太郎"
                register={register('name', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                })}
                errorMessage={errors.name}
                defaultValue={form.name}
              />
            </FormContent>
            <FormLabel isRequired>生年月日</FormLabel>
            <FormContent>
              <FormDatePicker
                id="birthday"
                control={control}
                errorMessage={errors.birthday}
                defaultValue={form.birthday}
              />
              <FormDateSelect<ContactCancel>
                fieldName="birthday"
                setValue={setValue}
                defaultValue={form.birthday}
              />
            </FormContent>
            <FormLabel isRequired>登録していた電話番号</FormLabel>
            <FormContent>
              <FormInput
                id="tel"
                type="tel"
                placeholder="0123456789"
                maxLength={11}
                register={register('tel', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    message: '電話番号を正しい形式で入力してください',
                    value: /^[0-9]+$/,
                  },
                })}
                errorMessage={errors.tel}
                hintText={['ハイフンなし']}
                defaultValue={form.tel}
              />
            </FormContent>
            <FormLabel isRequired>今回お手続きの連絡用メールアドレス</FormLabel>
            <FormContent>
              <FormInput
                id="email"
                type="email"
                placeholder="youremail@example.com"
                register={register('email', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    message: 'メールアドレスを正しい形式で入力してください',
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                })}
                errorMessage={errors.email}
                defaultValue={form.email}
              />
            </FormContent>
            <FormLabel isRequired>郵便番号</FormLabel>
            <FormContent>
              <FormInput
                id="zip_code"
                type="number"
                placeholder="0000000"
                onInput={(e) => sliceMaxLength(e, 7)}
                register={register('zip_code', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                  pattern: {
                    value: /^[0-9]{7}$/,
                    message: '半角数字7桁で入力してください',
                  },
                })}
                errorMessage={errors.zip_code}
                hintText={['ハイフンなし']}
                defaultValue={form.zip_code}
              />
            </FormContent>
            <FormLabel isRequired>住所</FormLabel>
            <FormContent>
              <FormInput
                id="address"
                type="text"
                placeholder="sample address"
                register={register('address', {
                  required: {
                    value: true,
                    message: '入力してください。',
                  },
                })}
                errorMessage={errors.address}
                defaultValue={form.address}
              />
            </FormContent>
            <FormFileUpload
              registerRequire={register('require_files', {
                required: {
                  value: true,
                  message: '選択してください。',
                },
              })}
              errorMessageRequire={errors.require_files}
              fileValidation={fileValidation}
            />
          </FormMain>
          <FormFooter />
          <FormAction>
            <Button
              showArrowRight
              type="submit"
              onClick={() => {
                fileErrors.length > 0 && setFileErrors([])
              }}
            >
              確認画面へ
            </Button>
          </FormAction>
        </form>
      </MainWrapper>
    </Layout>
  )
}
